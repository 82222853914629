import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import "react-component-countdown-timer/lib/styles.css";
import { connect } from "react-redux";
import { ClipLoader } from "react-spinners";
import Header from "../components/header/Header";
import { confirmAlert } from "react-confirm-alert";
import {handleKeyDown} from '../components/handlekeys/handlekey'
import {
  getQuestion,
  updateSelectedOption,
  updatePool,
  updateIndex,
  pageLoading,
  updateProgess,
  submitTest,
  getSwitchCount,
  updateNextIndex,
  updateNextPool
} from "../actions/test";
class Test extends Component {
  componentWillMount() {
    const driveName = localStorage.getItem("driveName");
    document.title = "Xenonstack Careers  |  " + driveName + "  |  Test Portal";
    
    
    window.addEventListener("blur", ()=>this.props.dispatch(getSwitchCount()));

    if (this.props.testToken === "") {
      this.props.history.push("/test-instructions");

    }

    document.addEventListener("click", handleKeyDown);
    document.addEventListener("keydown", handleKeyDown);
  }
  componentWillUnmount(){
    window.addEventListener("blur", function (event) {
      event.stopPropagation();
  }, true);
   
  }
 
  componentDidMount() {
    this.updateValues();
    let submitBody = {
      index: this.props.quesResult.nextIndex.toString(),
      pool: this.props.quesResult.nextPool,
      marked: "0",
      next_pool: this.props.quesResult.nextPool,
      next_index: this.props.quesResult.nextIndex.toString()
    };

    localStorage.setItem("token", this.props.testToken);
    this.props.dispatch(getQuestion(submitBody));

    document.addEventListener("click", handleKeyDown);
    document.addEventListener("keydown", handleKeyDown);
  }


  
  updateValues() {
    this.props.dispatch(updateIndex(this.props.quesResult.nextIndex));
    this.props.dispatch(updatePool(this.props.quesResult.nextPool));
    this.props.dispatch(updateNextIndex(this.props.quesResult.nextIndex+1));
    this.props.dispatch(updateNextPool(this.props.quesResult.nextPool));
    this.props.dispatch(updateProgess(this.props.quesResult.progress));
  }
  finishTest() {
    let body = {
      index: this.props.currentIndex.toString(),
      pool: this.props.currentPool,
      marked:
        this.props.selectedOption.toString() === ""
          ? "0"
          : this.props.selectedOption.toString(),
      next_pool: "",
      next_index: ""
    };

    this.props.dispatch(getQuestion(body));
    this.props.dispatch(submitTest(this.props.testToken));
    this.props.history.push("/overview");

    const options = {
      title: "Submitting Test!!  ",
      message: "Press Submit button to see results!",
      buttons: [
        {
          label: "Submit",
          onClick: () => {
            window.addEventListener("blur", function (event) {
              event.stopPropagation();
          }, true);
          }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: false
    };
    confirmAlert(options);
  }
  btnNext() {
    // updating progress when option is selected
    if (this.props.selectedOption !== "") {
      this.props.dispatch(updateProgess(this.props.progress + 1));
    }

    const allPools = this.props.testPools;
    const currentPool = this.props.currentPool;
    let poolName = this.props.currentPool;
    let totalQuestions = 0;
    for (let i = 0; i < allPools.length; i++) {
      if (allPools[i]["poolId"] === currentPool) {
        totalQuestions = allPools[i]["noOfQuestions"];
        if (i + 1 < allPools.length) {
          poolName = allPools[i + 1]["poolId"];
        }
      }
    }

    if (this.props.currentIndex !== totalQuestions) {
      this.props.dispatch(updateIndex(this.props.currentIndex + 1));
      //update next index
      if (this.props.nextIndex !== totalQuestions) {
        this.props.dispatch(updateNextIndex(this.props.nextIndex + 1));
      } else {
        this.props.dispatch(updateNextIndex(1));
        this.props.dispatch(updateNextPool(poolName));
      }
    } else {
      // shift to another pool
      const currentPool = this.props.currentPool;
      let poolName = this.props.currentPool;
      let allPools = this.props.testPools;

      for (let j = 0; j < allPools.length; j++) {
        if (allPools[j]["poolId"] === currentPool) {
          poolName = allPools[j + 1]["poolId"];

          // we are now at first question of first pool
          this.props.dispatch(updateIndex(1));
          this.props.dispatch(updateNextIndex(2));
          //change pool name
          this.props.dispatch(updatePool(poolName));
        }
      }
    }

    let body = {
      index: this.props.currentIndex.toString(),
      pool: this.props.currentPool,
      marked:
        this.props.selectedOption.toString() === ""
          ? "0"
          : this.props.selectedOption.toString(),
      next_pool: this.props.nextpool,
      next_index: this.props.nextIndex.toString()
    };
    
    this.props.dispatch(getQuestion(body));

    //empty the selected option
    this.props.dispatch(updateSelectedOption(""));
  }
  retrySubmitAnswer() {
    let body = {
      index: this.props.currentIndex.toString(),
      pool: this.props.currentPool,
      marked:
        this.props.selectedOption.toString() === ""
          ? "0"
          : this.props.selectedOption.toString(),
      next_pool: this.props.currentPool,
      next_index: this.props.nextIndex.toString()
    };

    this.props.dispatch(getQuestion(body));
  }
  render() {
    return (
      <div>
        <Header
			testDuration={this.props.testDuration}
			testName={this.props.testTestName}
			totalQuestions={this.props.testQuestions}
			currentQuestion={this.props.currentIndex}
        />
		<div className="content-wrapper">
        	<section id="main-content" className="test-portal-content-section">
				<div className="container">
					<div className="test-wrapper">
						<section className="test-portal-content-header">
							<div className="test-portal-header">                             
								<ul className="test-section-list">
									{ this.props.testPools.map((p, i) => (
										<li key={i} className={this.props.currentPool === p.poolId ? 'sec-name active': 'sec-name'} >
											<span>{p.poolName}</span><span className="pool-question-count">({p.noOfQuestions})</span>
										</li>
										))
									}
								</ul>
							</div>
						</section>
            			<div className="question-answer-block" data-simplebar>
                 			{this.props.Loader ?
								(<div className="submit-answer-loader-outer">
									<div className="submit-answe-laoder-content">
										<ClipLoader 
											sizeUnit={"px"}
											size={30}
											color={'#4a6fe6'}
											loading={this.props.pageLoading} />
										<p>Loading ...</p>
									</div>
								</div>):
								<div className="row">
									<div className="col-12 col-sm-12 col-md-12 col-lg-10 mar-xs-30">
										<div className="test-paper-questions">
                        <div className="question">
                          <span className="question-no">Q. {this.props.currentIndex}</span>
                          <div className="question-content">
                            <div dangerouslySetInnerHTML={{__html: (this.props.question.title || '')}}>
                            </div>
                            {(this.props.question.type === "images" && this.props.question.image_url.length > 0 )&&
                                        <div className="test-question-image">
                                            <ul className="test-question-image-ul">
                                              <li  className="width-auto"><img src={this.props.question.image_url}/></li>  
                                            </ul>
                                        </div>
                            }

											</div>
                    </div>
                    <div className="Answer-div">
                    { 
											(this.props.poolName !=="") && (this.props.questionOptions.length >0) &&
                        this.props.question.options.map((d, i) => (
                            <div onClick={()=> this.props.dispatch(updateSelectedOption(d.id))}
                                className={this.props.selectedOption === d.id ? "option-1 active" : "option-1"} key={i}>
                                <div className="list-number">{i + 1}</div>
                                  <div className="answer-content"><div dangerouslySetInnerHTML={{__html: (d.value || '')}}></div>
													        </div>
                              {(d.type === "images" && d.image_url !== "" ) &&
                                <div className="test-question-image">
                                  <ul className="test-question-image-ul">
                                    
                                        <li key={i}>
                                          <img src={d.image_url} alt="image"/>
                                        </li>
                                    
                                  </ul>
                                </div>
                              }  
                            </div>
                          ))                  
                          }
                          </div> 
                      </div>
                  </div>
              </div>
              } 
        </div>
				<section className="test-portal-footer">
                    <div className="test-portal-footer-content">
                        
                                <div className=" btn-group">
								{
									(this.props.testPools.length > 0 &&
									((this.props.currentIndex === this.props.testPools[this.props.testPools.length - 1]["noOfQuestions"]) && 
										(this.props.currentPool === this.props.testPools[this.props.testPools.length - 1]["poolId"])) &&
									<button
										type="submit"
										onClick={this.finishTest.bind(this)}
										className="btn signin">Submit
									</button>)
								}
								{
									(this.props.testPools.length > 0) &&
									( (this.props.currentPool === this.props.testPools[this.props.testPools.length - 1]["poolId"])
									&&(this.props.currentIndex === this.props.testPools[this.props.testPools.length - 1]["noOfQuestions"]) ) ?
										( <div></div> )
										:
										(
										<button type="submit" onClick={this.btnNext.bind(this)} disabled={
                      this.props.questionPageLoading === true ? true : false
                    }
                    className="btn signin">
										Submit & Next
										</button>
										)
								}      
                                </div>
							
					</div>
				</section>
				{/* <section className="submit-ans-block ">
					<div className="container">
						<div className="test-portal-footer">
							<div className="btn-group form-group">
								{
									(this.props.testPools.length > 0 &&
									((this.props.currentIndex === this.props.testPools[this.props.testPools.length - 1]["noOfQuestions"]) && 
										(this.props.currentPool === this.props.testPools[this.props.testPools.length - 1]["poolId"])) &&
									<button
										type="submit"
										onClick={this.finishTest.bind(this)}
										className="btn signin">Submit
									</button>)
								}
								{
									(this.props.testPools.length > 0) &&
									( (this.props.currentPool === this.props.testPools[this.props.testPools.length - 1]["poolId"])
									&&(this.props.currentIndex === this.props.testPools[this.props.testPools.length - 1]["noOfQuestions"]) ) ?
										( <div></div> )
										:
										(
										<button type="submit" onClick={this.btnNext.bind(this)} className="btn signin">
										Submit & Next
										</button>
										)
								}
							</div>
						</div>	
					</div>
				</section> */}
					</div>
				</div>
          	</section>
        </div>
        {this.props.error === 101 && (
          <aside className="react-dailog-box-aside">
            <div className="readt-dialog-box-dialog">
              <div className="readt-dialog-box-content">
                <h2>Something Went Wrong !!! </h2>
                <p>Please Try Again and Continue</p>
                <div className="dialog-footer">
                  <button
                    type="button"
                    onClick={this.retrySubmitAnswer.bind(this)}
                    className="btn"
                  >
                    Try Again
                  </button>
                </div>
              </div>
            </div>
          </aside>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    testDuration,
    testTestName,
    testQuestions,
    testToken,
    testPools,
    quesResult
  } = state.TestInstructionReducer;
  const {
    question,
    pageLoading,
    Loader,
    currentIndex,
    selectedOption,
    currentPool,
    progress,
    questionOptions,
    questionPageLoading,
    error,
    nextIndex,
    nextpool
  } = state.TestReducer;
  return {
    testDuration,
    testTestName,
    Loader,
    testQuestions,
    progress,
    testToken,
    question,
    questionOptions,
    pageLoading,
    currentIndex,
    testPools,
    selectedOption,
    currentPool,
    questionPageLoading,
    nextIndex,
    error,
    nextpool,
    quesResult
  };
}
export default withRouter(connect(mapStateToProps)(Test));
