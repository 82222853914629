import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import {
  beginTest,
  getQuestion,
  durationTest,
  pageLoading
} from "../../actions/test";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
class TestInstructions extends React.Component {
  constructor() {
    super();
    this.state = {
      startTest: false
    };
  }
  componentDidMount() {
    const url = window.location.href;
    const requiredUrl = new URL(url);

    const test_id = requiredUrl.searchParams.get("test_id");
    this.props.dispatch(durationTest(test_id));
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.testToken !== nextProps.testToken &&
      nextProps.beginTestPageLoading === false
    ) {
      this.props.history.push("/test");
    }
  }
  beginTest() {
    window.scrollTo(0, document.body.scrollHeight);
    const url = window.location.href;
    const requiredUrl = new URL(url);
    const token = requiredUrl.searchParams.get("token");
    const drive_id = requiredUrl.searchParams.get("drive_id");
    const test_id = requiredUrl.searchParams.get("test_id");
    const driveName = requiredUrl.searchParams.get("drivename");
    localStorage.setItem("driveName", driveName);

    // calls the api to generate new test token from old token
    if (drive_id && test_id && driveName) {
      this.props.dispatch(beginTest(token, drive_id, test_id));
      this.props.dispatch(pageLoading(true));
      document.getElementById("beginTestError").innerHTML = "";
    } else {
      document.getElementById("beginTestError").innerHTML =
        "Please restart your Test Window.";
    }
  }
  render() {
    const url = window.location.href;
    const requiredUrl = new URL(url);
    const driveName = requiredUrl.searchParams.get("drivename");
    return (
      <div>
        <header className="header header-fixed overview-header" id="header">
          <nav className="navigation-bar" id="navigation-bar">
            <div className="navigation-menu nav-left">
              <ul className="navbar-menu" id="navigation-menu-left">
                <li className="drop-down-menu">
                  <div class="brand-wrapper">
                    <div id="brand-logo" className="brand-image">
                      <figure>
                        <img
                          src={require("../../static/images/xenon-stack-logo3.png")}
                          alt="XenonStack"
                          width="100%"
                          height="100%"
                        />
                      </figure>
                    </div>
                  </div>
                </li>
                <li className="drop-down-menu">
                  <div className="drive-name-header">
                    <h2>{driveName}</h2>
                  </div>
                </li>
              </ul>
            </div>
            <div className="navigation-menu nav-right">
              <ul className="navbar-menu" id="navigation-menu-right">
                <li className="drop-down-menu">
                  <div className="remaining-time-box">
                    <span className="time">
                      <p className="remaining-time-head exam-status">
                        Duration :
                      </p>
                      {this.props.duration} min
                    </span>
                  </div>
                </li>
                <li className="drop-down-menu">
                  <div className="remaining-time-box">
                    <span className="time">
                      <p className="remaining-time-head exam-status">
                        Total Questions{" "}
                      </p>
                      {this.props.totalQuestions} Questions
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </nav>
        </header>
        <div className="content-wrapper">
          <section id="main-content" className="test-page">
            <div className="row">
              <div className="col-12 col-sm-12 offset-md-1 col-md-10 col-lg-8 offset-lg-2">
                <div className="test-paper-inst">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <h2 className="text-center">Test Instructions</h2>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                    <p className="subtitle">
                      This is a monitored Test, If you try to cheat, you will be
                      locked out of the Test. For the test to run smoothly, make
                      sure you follow these Instructions.
                    </p>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                    <div className="instructions-list mar-t-3">
                      <ul className="doc-list unordered plus-list-item">
                        <div className="webcam-window-outer"></div>
                        <li>
                          {" "}
                          The test comprises on two possible question types:
                          <ul>
                            <li>
                              <b>Multiple Choice:</b> click the radio button to
                              indicate your choice. Only one answer can be
                              selected for a multiple choice question.
                            </li>
                           
                            <li>
                              <b>True/False:</b> click the radio button to
                              indicate your choice.
                            </li>
                          </ul>
                        </li>
                        <li>
                          {" "}
                          Only one question will be displayed on your computer
                          screen at a time.{" "}
                        </li>
												<li>
													You cannot move among questions during the test.
                        </li>
                        <li>Close all programs, including email. </li>
                        <li>
                          Use a laptop or a desktop for hassle free experience.
                        </li>
                        <li> Use Firefox or Google Chrome for the test. </li>
                        <li>
													We track all your activities during the test:
                          <ul>
                            <li>Open or switch to other tabs or window during the test.</li>
                            <li>Screenshots during the test.</li>
                            <li>Stop and return to the test.</li>
                          </ul>
                        </li>
                      
                        <li>
                          If during the test you face any issues, do not panic.
                          Send us your queries at <b>careers@xenonstack.com </b>
                          and our team will get back to you.
                        </li>
                        <li>Click on Submit button to end the test.</li>
                        <li>
                          {" "}
                          We will appreciate your feedback after the test.{" "}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                    <label className="cstm-checkbox inst-checkbox">
                      I have read and understood the instructions. I agree that
                      in case of not adhering to the instructions, I shall be
                      liable to be debarred from this Test and/or to
                      disciplinary action, which may include ban from future
                      Tests.
                      <input
                        type="checkbox"
                        onClick={() =>
                          this.setState({
                            startTest: !this.state.startTest
                          })
                        }
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group text-center btn-group">
                    <button
                      type="button"
                      onClick={
                        this.state.startTest
                          ? this.beginTest.bind(this)
                          : undefined
                      }
                      className="btn mar-t-2"
                      style={{
                        background: `${
                          this.state.startTest ? "#48194e" : "#f3eaff"
                        }`,
                        color: `${
                          this.state.startTest ? "#f3eaff" : "#48194e"
                        }`
                      }}
                    >
                      {" "}
                      Ready to begin
                    </button>
                  </div>
                  {this.props.beginTestPageLoading && (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <div className="start-test-loader">
                        <ClipLoader
                          // css={override}
                          sizeUnit={"px"}
                          size={30}
                          color={"#48194e"}
                        />
                        <p>Please Wait, we are creating your test ... </p>
                      </div>
                    </div>
                  )}

                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <span id="beginTestError" style={{ color: "red" }}></span>
                  </div>

                  {this.props.beginTestError && (
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center">
                      <span style={{ color: "red" }}>
                        {this.props.beginTestMessage}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const {
    duration,
    beginTestError,
    beginTestMessage,
    beginTestStatus,
    beginTestPageLoading,
    testToken,
    testExpire,
    totalQuestions
  } = state.TestInstructionReducer;
  return {
    duration,
    beginTestError,
    beginTestMessage,
    beginTestStatus,
    beginTestPageLoading,
    testToken,
    testExpire,
    totalQuestions
  };
}
export default withRouter(connect(mapStateToProps)(TestInstructions));
