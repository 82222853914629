import { combineReducers } from 'redux';
import appReducer from './appReducer';
import TestInstructionReducer from './testReducer';
import TestReducer from './submitReducer';

// import reducers from the reducers export all, making this as root reducer
// export default combineReducers({
// })
export default combineReducers({
    appReducer,
    TestInstructionReducer,
    TestReducer
});