import {BEGIN_TEST_FAILURE, BEGIN_TEST_REQUEST, BEGIN_TEST_SUCCESS
    ,GET_DURATION_TEST_REQUEST
    ,GET_DURATION_TEST_SUCCESS,GET_DURATION_TEST_FAILURE,
    
    } from "../types/test";


export const initialState = {
    beginTestError: "",
    beginTestMessage: "",
    beginTestStatus: "",
    beginTestPageLoading: false,
    durationPageLoading:false,
    duration:'',
    testDuration:'',
    totalQuestions:'',
    durationTestError:"",
    durationTestMessage:'',
    durationTestStatus:'',
    testToken: "",
    testExpire: "",
    testStart: "",
    testPools: [],
    testQuestions: 0,
    testTestName: "",
    testDriveName: "",
    testEmail: "",
    quesResult:{
        currentIndex:'',
        currentPool:'',
        nextIndex:	'',
        nextPool:'',
        progress:'',
    }
};


export default function TestInstructionReducer(state = initialState, action) {
    switch (action.type) {
        case BEGIN_TEST_REQUEST:
        
            return Object.assign({}, state, {
                beginTestPageLoading: true
            });
        case BEGIN_TEST_SUCCESS:
            if (action.response.data.error) {
                return Object.assign({}, state, {
                    beginTestPageLoading: false,
                    beginTestStatus: 200,
                    beginTestError: true,
                    beginTestMessage: action.response.data.message,
                    testToken: "",
                    testExpire: "",
                    testStart: "",
                    testPools: [],
                    testQuestions: 0,
                    testTestName: "",
                    testDriveName: "",
                    testEmail: ""
                });
            } else {
                if (!!action.response.data.token
                    && !!action.response.data.expire
                    // response of start is null on first click of Start Test    
                    && !!action.response.data.start
                    && !!action.response.data.pools
                    && !!action.response.data.questions
                    && !!action.response.data.test_name
                    && !!action.response.data.drive_name
                    && !!action.response.data.email) {
                        
                    return Object.assign({}, state, {
                        beginTestPageLoading: false,
                        beginTestStatus: 200,
                        beginTestError: false,
                        beginTestMessage: "",
                        testToken: action.response.data.token,
                        testExpire: action.response.data.expire,
                        testStart: action.response.data.start,
                        testPools: action.response.data.pools,
                        testQuestions: action.response.data.questions,
                        testTestName: action.response.data.test_name,
                        testDriveName: action.response.data.drive_name,
                        testEmail: action.response.data.email,
                        testDuration:action.response.data.duration,
                        quesResult:{
                            currentIndex: action.response.data.next_index,
                            currentPool: action.response.data.next_pool,
                            nextIndex: action.response.data.next_index,
                            nextPool: action.response.data.next_pool,
                            progress:action.response.data.progress,
                        }
                    });
                    
                } else {
                    
                    return Object.assign({}, state, {
                        beginTestPageLoading: false,
                        beginTestStatus: 200,
                        beginTestError: true,
                        beginTestMessage: action.response.data.message,
                        testToken: "",
                        testExpire: "",
                        testStart: "",
                        testPools: [],
                        testQuestions: 0,
                        testTestName: "",
                        testDriveName: "",
                        testEmail: "",
                        quesResult:{
                            currentIndex: '',
                            currentPool: '',
                            nextIndex: '',
                            nextPool: '',
                            progress:'',
                        }
                    });
                }
            }
        case BEGIN_TEST_FAILURE:
            return Object.assign({}, state, {
                beginTestPageLoading: false,
                beginTestStatus: action.response.status,
                beginTestError: true,
                beginTestMessage: action.response.data.message,
                testToken: "",
                testExpire: "",
                testStart: "",
                testPools: [],
                testQuestions: 0,
                testTestName: "",
                testDriveName: "",
                testEmail: ""
            });
            case GET_DURATION_TEST_REQUEST:
    
            return Object.assign({}, state, {
                durationPageLoading: true
            });
        case GET_DURATION_TEST_SUCCESS:
       
        if (action.response.data.error) {
            return Object.assign({}, state, {
                durationPageLoading: false,
                duration:'',
                totalQuestions:'',
                durationTestError:false,
                durationTestMessage:'',
                durationTestStatus:'',
            });
        } else {
                return Object.assign({}, state, {
                    durationPageLoading: false,
                    durationTestError:false,
                    durationTestMessage:action.response.data.message,
                    durationTestStatus:200,
                    duration: action.response.data.test.duration,
                    totalQuestions:action.response.data.test.totalQuestions,
                });
            }
        
        case GET_DURATION_TEST_FAILURE:
        return Object.assign({}, state, {
            durationPageLoading: false,
            duration:'',
            totalQuestions:'',
            durationTestError:true,
            durationTestMessage:'',
            durationTestStatus:'',
        });

        // case CLEAR_ALL:
        //     return Object.assign({}, state, {
        //         beginTestError: "",
        //         beginTestMessage: "",
        //         beginTestStatus: "",
        //         testToken: "",
        //         testExpire: "",
        //         testStart: "",
        //         testPools: [],
        //         testQuestions: 0,
        //         testTestName: "",
        //         testDriveName: "",
        //         testEmail: ""
        //     });
       
        default:
            return state
    
        }
    }