export const BEGIN_TEST_REQUEST = "BEGIN_TEST_REQUEST";
export const BEGIN_TEST_SUCCESS = "BEGIN_TEST_SUCCESS";
export const BEGIN_TEST_FAILURE = "BEGIN_TEST_FAILURE";


export const GET_DURATION_TEST_REQUEST='GET_DURATION_TEST_REQUEST';
export const GET_DURATION_TEST_SUCCESS='GET_DURATION_TEST_SUCCESS';
export const GET_DURATION_TEST_FAILURE='GET_DURATION_TEST_FAILURE';

export const GET_QUESTION_REQUEST='GET_QUESTION_REQUEST';
export const GET_QUESTION_SUCCESS='GET_QUESTION_SUCCESS';
export const GET_QUESTION_FAILURE='GET_QUESTION_FAILURE';

export const UPDATE_CURRENT_INDEX="UPDATE_CURRENT_INDEX";
export const UPDATE_NEXT_INDEX="UPDATE_NEXT_INDEX";
export const UPDATE_SELECTED_OPTION="UPDATE_SELECTED_OPTION"


export const UPDATE_CURRENT_POOL='UPDATE_CURRENT_POOL'
export const UPDATE_NEXT_POOL='UPDATE_NEXT_POOL'
export const POST_SUBMIT_TEST_REQUEST = 'POST_SUBMIT_TEST_REQUEST';
export const POST_SUBMIT_TEST_SUCCESS = 'POST_SUBMIT_TEST_SUCCESS';
export const POST_SUBMIT_TEST_FAILURE = 'POST_SUBMIT_TEST_FAILURE';

export const PUT_SWICH_COUNT_REQUEST='PUT_SWICH_COUNT_REQUEST'
export const PUT_SWICH_COUNT_SUCCESS='PUT_SWICH_COUNT_SUCCESS'
export const PUT_SWICH_COUNT_FAILURE='PUT_SWICH_COUNT_FAILURE'

export const PAGE_LOADING_VALUE='PAGE_LOADING_VALUE';

export const UPDATE_PROGRESS_COUNT='UPDATE_PROGRESS_COUNT'