import { GET_API } from './symbols';

import { isValidJSON } from './index'

function getApi(endpoint, authenticated,testToken){
    const url = window.location.href;
        const requiredUrl = new URL(url);
        var token = requiredUrl.searchParams.get("token");
        let config = {
            method: 'GET'
        }
        if(testToken){token=testToken}
        if ( token ) {
        
            config['headers'] = {
                'Authorization': `Bearer ${token}`
            }
        }

    return fetch(endpoint, config)
        .then((response) => {
            if (response.status === 200) {
                return response.text().then(data => {
                    return isValidJSON(data) ? ({data: JSON.parse(data), status: response.status}) : ({
                        data: {
                            error: true,
                            message: "Error while parsing the json."
                        }, status: response.status
                    })
                })
            } else {
                return response.json().then(data =>
                    ({data, status: response.status})
                )
            }
        })
        .catch(err => ({data: {error: true, message: "Internal Server Error"}, status: 500}));
}

export default store => next => action => {
    const getApiDetails = action[GET_API]
    if (typeof getApiDetails === 'undefined') {
        return next(action);
    }
    let {endpoint, types, authenticated,testToken} = getApiDetails;
    const [requestType, successType, errorType] = types;
    return (
        next({type: requestType}),
        getApi(endpoint, authenticated,testToken).then(
        response => {
            if (response.status === 200) {
                return next({
                    response,
                    type: successType
                })
            } else {
                return next({
                    response,
                    type: errorType
                })
            }
        }
    ))
}