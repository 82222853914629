import {
    BEGIN_TEST_REQUEST, BEGIN_TEST_SUCCESS, BEGIN_TEST_FAILURE,GET_DURATION_TEST_REQUEST
    ,GET_DURATION_TEST_SUCCESS,GET_DURATION_TEST_FAILURE, GET_QUESTION_REQUEST, GET_QUESTION_SUCCESS, GET_QUESTION_FAILURE
    ,UPDATE_CURRENT_INDEX,UPDATE_CURRENT_POOL,PAGE_LOADING_VALUE,
    UPDATE_SELECTED_OPTION,
    POST_SUBMIT_TEST_REQUEST,
    POST_SUBMIT_TEST_SUCCESS,UPDATE_NEXT_POOL,
    POST_SUBMIT_TEST_FAILURE,UPDATE_PROGRESS_COUNT,
    PUT_SWICH_COUNT_REQUEST, PUT_SWICH_COUNT_SUCCESS,PUT_SWICH_COUNT_FAILURE,UPDATE_NEXT_INDEX
} from "../types/test";
import {GET_API, PUT_API} from "../middleware/symbols";
import {CALL_POST_API} from '../middleware/post-api'
const BASE_URL = process.env.REACT_APP_DRIVE_API
const TEST_URL = process.env.REACT_APP_TEST_URL
 export function beginTest(token, drive_id, test_id) {
    return {
        [GET_API]: {
            endpoint:BASE_URL+ '/v1/user/drives/'+drive_id+'/'+test_id,
            types: [BEGIN_TEST_REQUEST, BEGIN_TEST_SUCCESS, BEGIN_TEST_FAILURE],
            token
        }
    }
}
export function durationTest(test_id){
    return {
       
        [GET_API]: {
            endpoint:BASE_URL+ '/v1/user/test/'+test_id,
            types: [GET_DURATION_TEST_REQUEST, GET_DURATION_TEST_SUCCESS,GET_DURATION_TEST_FAILURE],

        }
    }
}
export function getQuestion(body){
   
    return {
        [CALL_POST_API]: {
           
            endpoint: TEST_URL+"/v1/markandfetch",
            types: [GET_QUESTION_REQUEST,GET_QUESTION_SUCCESS,GET_QUESTION_FAILURE],
            body,
            
        }
    }
}
export function getSwitchCount(){
    return {
        [PUT_API]: {
            endpoint:TEST_URL+ '/v1/increment-switch',
            types: [PUT_SWICH_COUNT_REQUEST, PUT_SWICH_COUNT_SUCCESS,PUT_SWICH_COUNT_FAILURE],
            authenticated:true

        }
    }
}

export function submitTest(token){
    return{
        [GET_API]: {
            endpoint: TEST_URL+"/v1/submittest",
            types: [POST_SUBMIT_TEST_REQUEST,POST_SUBMIT_TEST_SUCCESS,POST_SUBMIT_TEST_FAILURE],
            testToken:token
        }
    }
}

//update curent index
export function updateIndex(index){
    return{type:UPDATE_CURRENT_INDEX,index}
}

//update curent index
export function updateNextIndex(index){
    return{type:UPDATE_NEXT_INDEX,index}
}

// update the id of selected option of question
export function updateSelectedOption(option){
    return {type:UPDATE_SELECTED_OPTION,option}
}

//update the count of progress in progress bar
export function updateProgess(count){
    return {type:UPDATE_PROGRESS_COUNT,count}
}

//move to next pool
export function updatePool(poolName){
    return{type:UPDATE_CURRENT_POOL,   poolName}
}
export function updateNextPool(poolName){
    return{type:UPDATE_NEXT_POOL,   poolName}
}

//loader value
export function pageLoading(value){
    return {type:PAGE_LOADING_VALUE,value}
}