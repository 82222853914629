import {GET_QUESTION_SUCCESS,GET_QUESTION_FAILURE,PAGE_LOADING_VALUE,GET_QUESTION_REQUEST,
     UPDATE_CURRENT_INDEX, UPDATE_SELECTED_OPTION, UPDATE_CURRENT_POOL,
     POST_SUBMIT_TEST_FAILURE,POST_SUBMIT_TEST_REQUEST,POST_SUBMIT_TEST_SUCCESS,UPDATE_PROGRESS_COUNT,
    PUT_SWICH_COUNT_REQUEST,PUT_SWICH_COUNT_SUCCESS,PUT_SWICH_COUNT_FAILURE, UPDATE_NEXT_INDEX,
    UPDATE_NEXT_POOL} from "../types/test";


export const initialState = {
   pageLoading:false,
   questionPageLoading:false,
   errorMessage:'',
   status:'',
   error:false,
   submitBody:{
       questionIndex:'',
       currentPool:'',
       marked:'',
       nextPool:'',
       nextIndex:'',
   },
   nextPool:'',
   question:[],
   questionOptions:[],
   currentIndex:1,
   nextIndex:2,
   selectedOption:'',
   Loader:false,
   submitPageLoading:false,
   submitError:false,
   attempted:'',
   unanswered:'',
   timeTaken:'',
   totalQues:'',
   progress:0,
   switchPageLoading:false,


   
};


export default function TestReducer(state = initialState, action) {
    switch (action.type) {
        case GET_QUESTION_REQUEST:
            return Object.assign({}, state, {
                questionPageLoading:true,
                Loader:true
            });
        case GET_QUESTION_SUCCESS:
            return Object.assign({}, state, {
                questionPageLoading:false,
                question:action.response.data.question,
                questionOptions:(action.response.data.question.option === null)? 
              []
                
                :action.response.data.question.options,
                errorMessage:action.response.data.message,
                status:action.response.data.status,
                error:action.response.status,
                Loader:false
                // currentIndex:currentIndex+1
            });
        case GET_QUESTION_FAILURE:
       
            return Object.assign({}, state, {
                questionPageLoading:false,
                error:101,
                Loader:false
            });
        case POST_SUBMIT_TEST_REQUEST:
            return Object.assign({}, state, {
                submpageLoading:true,
            });
        case POST_SUBMIT_TEST_SUCCESS:
            return Object.assign({}, state, {
                submpageLoading:false,
                attempted:action.response.data.message.attempted,
                unanswered:action.response.data.message.unanswered,
                timeTaken:action.response.data.message.timetaken,
                totalQues:action.response.data.message.total

                // currentIndex:currentIndex+1
            });
        case POST_SUBMIT_TEST_FAILURE:
            return Object.assign({}, state, {
                submpageLoading:false,
            });
            case PUT_SWICH_COUNT_REQUEST:
            return Object.assign({}, state, {
                switchPageLoading:true
            });
        case PUT_SWICH_COUNT_SUCCESS:
        
            return Object.assign({}, state, {
                switchPageLoading:false
            });
        case PUT_SWICH_COUNT_FAILURE:
            return Object.assign({}, state, {
                switchPageLoading:false
            });
        case UPDATE_CURRENT_INDEX: 
            return Object.assign({},state,{
                currentIndex:action.index
            })
        case UPDATE_NEXT_INDEX: 
            return Object.assign({},state,{
                nextIndex:action.index
            })
       
        case UPDATE_SELECTED_OPTION: 
            return Object.assign({},state,{
                selectedOption:action.option,
            
            })
         
        case UPDATE_CURRENT_POOL:
        return Object.assign({},state,{
            currentPool:action.poolName,
          
            // nextpool:action.nextPoolName
        })
        case UPDATE_NEXT_POOL:
        return Object.assign({},state,{
           
          
            nextpool:action.poolName
        })
        case PAGE_LOADING_VALUE:
        return Object.assign({}, state, {
            Loader: action.value
        });
        case UPDATE_PROGRESS_COUNT:
        return Object.assign({}, state, {
            progress: action.count
        });
        default:
            return state
    
        }
    }