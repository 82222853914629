import React, { Component } from 'react';
import {connect} from 'react-redux';
import {handleKeyDown} from '../../components/handlekeys/handlekey'

class TestOverview extends Component {
    componentWillMount(){
        document.title = "Xenonstack Careers | Test Overview ";
        document.addEventListener("click", handleKeyDown);
        document.addEventListener("keydown", handleKeyDown);
    }
    componentDidMount(){
        document.addEventListener("click", handleKeyDown);
        document.addEventListener("keydown", handleKeyDown);
    }
    render() {
        const driveName=localStorage.getItem('driveName')
        //code for calculation time
        var d=this.props.timeTaken
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);
        var mDisplay = m > 0 ? m + (m == 1 ? " min.  " : " min. ") : "";
        var sDisplay = s > 0 ? s + (s == 1 ? " sec." : " sec.") : "";     

        return (
           <div className="test-overview-wrapper"> 
            <header className="header header-fixed overview-header" id="header">
                <nav className="navigation-bar" id="navigation-bar">
                    <div className="navigation-menu nav-left">
                        <ul className="navbar-menu" id="navigation-menu-left">
                        <li className="drop-down-menu">
                            <div class="brand-wrapper">
                            <div id="brand-logo" className="brand-image">
                            <img src={require("../../static/images/xenon-stack-logo3.png")} alt="XenonStack"
                                               width="100%"
                                                height="100%"/>
                            </div>
                        </div>
                        </li>
                            <li className="drop-down-menu">
                                <div className="drive-name-header">
                                    <h2>{driveName}</h2>
                                </div>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>  
                <section id="main-content" className="test-page">
                
                    <div className="row">
                        <div className="col-md-12">
                            <div className="content-wrapper">
                                <section className="Question-section">
                                <form >                                    <div className="row">
                                        <div className="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
                                            <div className="test-paper-inst">
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                <h2 className="text-center">Test Completed!</h2>
                                                </div>
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                                    <p className="subtitle text-center" style={{color:"#48194e" }}>You have successfully completed the test.</p>
                                                </div>
                                                {(!this.props.questionPageLoading && !this.props.submpageLoading)&&
                                                <div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group">
                                                    <div className="test-overview mar-t-3">
                                                        <ul className="overview-items">
                                                            <li>Question Attempted<p>{this.props.attempted}/{this.props.totalQues}</p></li>
                                                            <li>Question Unanswered<p>{this.props.unanswered}</p></li>
                                                            <li>Time Taken <p> {mDisplay + sDisplay}</p></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                 }
                                                 <div className="col-12 col-sm-12 col-md-12 col-lg-12 form-group text-center btn-group">
                                                 <h3 className="text-center">Share your experience with us!</h3>
                                                 <a href={process.env.REACT_APP_BASE_URL+"/app/feedback"}  className="btn mar-t-2">Close & Send Feeback </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </form>
                                </section>
                            </div>
                        </div>

                    </div>


                </section>
            
            </div>
        )
    }
}
function mapStateToProps(state) {
    const {unanswered,
        attempted,
        timeTaken,submpageLoading,questionPageLoading,
        totalQues}=state.TestReducer
    return {
        unanswered,
        attempted,
        timeTaken,
        totalQues,submpageLoading,questionPageLoading,

    };
}

// for dispatching the actions


// for sending new props to component.
// @ts-ignore
export default connect(mapStateToProps)(TestOverview)

