export function handleKeyDown  (event)  {      
    switch( event.keyCode ) {
        case 116:             
           event.preventDefault();
        return false;       
        case 27:             
            event.preventDefault();
        return false;       
        case 17:             
            event.preventDefault();
        return false;       
        case 82:             
            event.preventDefault();
        return false;       
        case 123:             
            event.preventDefault();
        return false;       
        case 16:             
            event.preventDefault();
            return false;                       
        default: 
        return false;
    }
    }