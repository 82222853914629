import React, { Component } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import TestInstructions from '../components/test-instruction/testinstructions';
import Test from '../container/test';
import TestOverview from '../components/test-overview/testoverview';

/**
 * This function checks wheather the user is logged in or logged out.
 * isAuthenticated === true - logged in
 * isAuthenticated === false - logged out
 * 
 * we can change it, but its name needs to be the same and
 *  it needs to return a true or false resemblance values
 */

/* export function checkAuth() {
    const {auth} = store.getState().LoginReducer;
    const {isAuthenticated} = auth;
    return isAuthenticated
}
*/
class RouteComponent extends Component {
    render() {
        return (
                <div>
                        <BrowserRouter>
                            <Switch>
                                
                                <Route exact path="/test-instructions" component={TestInstructions} />
                                <Route exact path="/test" component={Test} />
                                <Route exact path="/overview" component={TestOverview} />
                            </Switch>
                            </BrowserRouter>    
                </div>
                
        )
    }
}
function mapStateToProps(state) {
    const {toggleStatus} = state.appReducer;
    return {toggleStatus}
}

export default connect(mapStateToProps)(RouteComponent);