import  React  from "react";
import CountdownTimer from "react-component-countdown-timer";
import { NavLink,withRouter } from "react-router-dom";
import { submitTest } from "../../actions/test";
import {connect} from 'react-redux'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class Header extends React.Component {
  submitTest(){
    console.log('submit test*******************')
    const options = {
      title: "Submitting Test!!  ",
      message: 'Are you sure you want to submit the Test ?',
      buttons: [
        {
          label: 'Submit',
          class: "submit-btn",
          onClick: () => {
                  this.props.dispatch(submitTest(this.props.testToken))
                  this.props.history.push('/overview')
                }
        },
        {
            label:'Cancel',
            class:'cancel-btn',
            onClick:()=>{
            }
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: false,

    };
      confirmAlert(options)
  }
  render() {
    
    let totalSeconds =this.props.testDuration
    let settings = {
      count: totalSeconds,
      responsive: true,
      border: false,
      showTitle: false,
      noPoints: false,
      hideDay:true,
      onEnd: () => {
          // calls timesUpDialog function to automaticalls calls rpc to submit the test because time is over
          this.props.dispatch(submitTest(this.props.testToken))
          confirmAlert(options);
      }

  };

  let thi=this;
  const options = {
      title: "Times Up!!",
      message: 'Click on the button to submit the Test.Thankyou for your time.',
      buttons: [
        {
          label: 'Finish Test',
          onClick: () => {
              
                  thi.props.history.push('/overview')
              }
        },

      ],

      closeOnEscape: false,
      closeOnClickOutside: false,

    };    
    return (
		<header className="header header-fixed" id="header">
            <nav className="navigation-bar" id="navigation-bar">
				<div className="navigation-menu nav-left">
					<ul className="navbar-menu" id="navigation-menu-left">
						<li className="drop-down-menu">
							<div class="brand-wrapper">
								<div id="brand-logo" className="brand-image">
									<figure>
										<img src={require("../../static/images/xenon-stack-logo3.png")}
										alt="XenonStack" width="100%" height="100%" />
									</figure>
								</div>
							</div>
						</li>
						<li className="drop-down-menu">
							<div className="drive-name-header">
								<p>{this.props.testName}</p>
							</div>
						</li>
					</ul>
				</div>
				<div className="navigation-menu nav-right">
					<ul className="navbar-menu" id="navigation-menu-right">
						<li className="drop-down-menu">
								<p className="remaining-time-head">Progress</p>
                <div className="remaining-time-box"><span className="time">{this.props.progress}/{this.props.totalQuestions}</span>
                </div>
						</li>
						<li className="drop-down-menu">
							<p className="remaining-time-head">Remaining Time</p>
							<div className="remaining-time-box">
								<CountdownTimer {...settings} />
							</div>
						</li>
						<li className="drop-down-menu">
							<div className="finish-btn-wrapper">
								<button type="submit" onClick={this.submitTest.bind(this)} className="btn finish-btn"> Finish Test </button>
							</div>
						</li>
					</ul>
				</div>
			</nav>
        </header>
    );
  }
}

function mapStateToProps(state) {
  const {
   
    testToken
  } = state.TestInstructionReducer;
  const {progress}=state.TestReducer
  return { testToken,progress};
}
export default withRouter(connect(mapStateToProps)(Header))
